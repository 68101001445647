
  import { Component, Vue } from 'vue-property-decorator'
  import { QuizReviewDataInterface, QuizReviewInterface, QuizInterface, UserAnswerInterface, UserInfoInterface } from '@/interface/BaseInterface'
  import getApi from '@/api'
  import QuizReview from '../../api/quizReview'
  import { size, findIndex, filter } from 'lodash'
  import QuizApi from '../../api/quizApi'
  import QuizAttempt from '../../api/quizAttempt'
  import { namespace } from 'vuex-class'
  import QuizTestResult from '../../components/quizPreview/QuizTestResult.vue'

  const quizReview: QuizReview = getApi('quizReview')
  const quizApi: QuizApi = getApi('quiz')
  const quizAttempt: QuizAttempt = getApi('quizAttempt')

  const tQuizResult = namespace('tQuizResult')

  @Component({ components: { QuizTestResult } })
  export default class QuizUserReviewPage extends Vue {
    @tQuizResult.Action('setAttemptResult') setAttemptResult!: (data: UserAnswerInterface[]) => void

    data: QuizReviewDataInterface[] = []

    isLoading = false

    activeName = '0'

    attemptSelectedId = ''

    reviewId = ''

    quiz: QuizInterface = {
      _id: '',
      name: '',
      courseId: this.courseId,
      sectionId: '',
      parts: []
    }

    teachers: UserInfoInterface[] = []
    teacherSelect = ''

    isReload = true
    isSubmit = false
    conflictClick = false

    get quizSelected() {
      return this.data[Number(this.activeName)] || undefined
    }

    async init() {
      try {
        if (!this.teacherSelect) {
          return
        }
        this.isLoading = true
        this.data = await quizReview.getAssignAttemptByUser(this.courseId, this.teacherSelect)
        if (size(this.data) > 0) {
          if (size(this.data[0].attempts) > 0) {
            this.attemptSelectedId = this.data[0].attempts[0].attemptId
            this.handleAttemptClick(this.data[0].attempts[0])
          }
        }
        this.isLoading = false
      } catch (error) {
        this.data = []
        this.attemptSelectedId = ''
        this.isLoading = false
      }
    }

    handleCourseChange() {
      console.log('chagnge')
      if (this.quizSelected) {
        if (size(this.quizSelected.attempts) > 0) {
          this.attemptSelectedId = this.quizSelected.attempts[0].attemptId
          this.handleAttemptClick(this.quizSelected.attempts[0])
        }
      }
    }

    async handleAttemptClick(value: QuizReviewInterface) {
      if (this.conflictClick) {
        return
      }
      try {
        this.isReload = true
        this.attemptSelectedId = value.attemptId
        this.reviewId = value._id
        if (this.quiz._id !== value.quizId) {
          this.quiz = await quizApi.getById(value.quizId)
        }
        this.setAttemptResult(await quizAttempt.attemptDetailByUser(this.quiz._id, value.attemptId, value.studentId))
        this.isReload = false
      } catch (error) {
        console.log(error)
      }
    }

    handleRemoveReview(item: QuizReviewInterface) {
      this.conflictClick = true
      this.$confirm(this.$t('deleteQuestion') as string, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        await quizReview.removeReview(this.courseId, item._id)

        const quizIndex = findIndex(this.data, value => {
          return value.quiz._id === this.quiz._id
        })

        if (quizIndex !== -1) {
          this.data[quizIndex].attempts = filter(this.data[quizIndex].attempts, value => {
            return value._id !== item._id
          })
          if (size(this.data[quizIndex].attempts) === 0) {
            this.data = filter(this.data, (value, key) => {
              return key !== quizIndex
            })
            this.reset()
            if (size(this.data) > 0) {
              if (size(this.data[0].attempts) > 0) {
                this.attemptSelectedId = this.data[0].attempts[0].attemptId
                this.handleAttemptClick(this.data[0].attempts[0])
              }
            }
          }
        }
      })
    }

    reset() {
      this.attemptSelectedId = ''
      this.reviewId = ''
      this.quiz = {
        _id: '',
        name: '',
        courseId: this.courseId,
        sectionId: '',
        parts: []
      }
    }

    async handleSubmit() {
      try {
        this.isLoading = true
        await quizReview.submit(this.courseId, this.reviewId)

        const quizIndex = findIndex(this.data, value => {
          return value.quiz._id === this.quiz._id
        })

        if (quizIndex !== -1) {
          this.data[quizIndex].attempts = filter(this.data[quizIndex].attempts, value => {
            return value.attemptId !== this.attemptSelectedId
          })
          if (size(this.data[quizIndex].attempts) === 0) {
            this.data = filter(this.data, (value, key) => {
              return key !== quizIndex
            })
            this.reset()
            if (size(this.data) > 0) {
              if (size(this.data[0].attempts) > 0) {
                this.attemptSelectedId = this.data[0].attempts[0].attemptId
                this.handleAttemptClick(this.data[0].attempts[0])
              }
            }
          }
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    handleTeacherChange() {
      this.init()
    }

    async initTeacher() {
      try {
        this.teachers = await quizReview.listTeachers(this.courseId)
        if (size(this.teachers) > 0) {
          this.teacherSelect = this.teachers[0]._id
        }
      } catch (error) {
        this.teachers = []
      }
    }

    async created() {
      await this.initTeacher()
      await this.init()
    }
  }
